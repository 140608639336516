import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Badge } from "react-bootstrap";
import i18next from "i18next";
import { ReactComponent as HomeIcon } from "../assets/home.svg";
import { routes } from "../utils/sideMenuRoutes";
import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { Elgiganten_siteRoutes } from "../../../../branchAndBrandSpecific/utils/brandVariables/siteRoutes/Elgiganten_siteRoutes";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { BestGamer_siteRoutes } from "./../../../utils/brandVariables/siteRoutes/Bestgamer_siteRoutes";

export const SideMenuLinks: React.FunctionComponent<
  any & { closeWindow?: () => void }
> = ({ currentViewName, closeWindow }) => {
  const [menuRoutes, setMenuRoutes] = useState(routes);
  const currenUser = useCurrentUserProfile((s) => s.user);
  useEffect(() => {
    if (brand.brandValue === "ELGIGANTEN") {
      setMenuRoutes(Elgiganten_siteRoutes as any);
    }
    if (brand.brandValue === "BESTGAMER") {
      setMenuRoutes(BestGamer_siteRoutes as any);
    }
  }, [brand, menuRoutes]);

  return (
    <nav className="primary-nav__nav">
      {Object.entries(menuRoutes).map(([key, value]) => (
        <div key={key} className="primary-nav__section">
          {key && value[0]?.title && (
            <div className="primary-nav__heading">
              <h4>{key}</h4>
            </div>
          )}

          <ul className="primary-nav__list">
            {key === "social" ? (
              <li
                className="primary-nav__item primary-nav__item--home"
                key={key}
                onClick={closeWindow}
              >
                <Link
                  to="/home"
                  className="primary-nav__link"
                  onClick={closeWindow}
                >
                  <span className="primary-nav__link--contents">
                    <HomeIcon />
                    <span>Homepage</span>
                  </span>
                </Link>
              </li>
            ) : (
              ""
            )}
            {value.map((v) => {
              if (v) {
                return (
                  <li
                    key={v.title}
                    className={`primary-nav__item ${
                      v.link === currentViewName ? "active" : ""
                    }`}
                  >
                    <Link
                      to={`${
                        v.link.includes("http")
                          ? `${v.link}/${currenUser.uid}_${currenUser.userName}`
                          : v.link
                      }`}
                      className="primary-nav__link"
                      onClick={closeWindow}
                      target={`${v.link.includes("http") ? "_blank" : ""}`}
                    >
                      <span className="primary-nav__link--contents">
                        {v.logo && <v.logo width={24} height={24} />}
                        <span>{i18next.t(v.title)}</span>
                      </span>
                      {v.title === "Messages" &&
                        localStorage!.getItem("hasMessages") === "true" &&
                        v.link !== currentViewName && (
                          <div className="badge-wrapper">
                            <Badge bg="primary">New</Badge>
                          </div>
                        )}
                    </Link>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      ))}
    </nav>
  );
};
