import React, { FC, useEffect, useState } from "react";
import useDocumentTitle from "../../../src/hooks/useDocumentTitle";
import firebase from "firebase/app";
import i18next from "i18next";
import LeaderboardUserItem from "./components/LeaderboardItem/index";
import elgigantenLogo from "../../../branchAndBrandSpecific/utils/brandVariables/assets/elgiganten/elgiganten.svg";
import { days, fullDays } from "@/utils/constants/weekDays";
import { schedule1337 } from "./1337Schedule";
import { Challenge1337VerticlePageEmpty } from "./1337.challene.page.empty";
import QRCode from "react-qr-code";
import { ReactComponent as ArrowCurved } from "./assets/arrowCurved.svg";
const Challenge1337VerticlePage: FC = (): JSX.Element => {
  const isLargeVersion = window.location.pathname === "/1337-challenge-lg";
  useDocumentTitle("1337 Challenge");
  const today2 = new Date();
  const currentDay2 = fullDays[days[today2.getDay()]];
  const localAttempts = localStorage.getItem("attemptCount");
  localAttempts !== undefined ? Number(localAttempts) : 0;

  type LeaderboardItemTypes = {
    uid: string;
    attempts: number;
    userName: string;
    winningDate: any;
    score: number;
    winningDay: string;
  };

  const [leaderboardData, setLeaderboardData] = useState<
    LeaderboardItemTypes[]
  >([]);

  const getCurrentSchedule = () => {
    const today = new Date();
    const currentDay = fullDays[days[today.getDay()]];
    const currentHour = today.getHours();

    const currentSchedule = schedule1337.find(
      (item) =>
        item.day === currentDay &&
        currentHour >= item.startHour &&
        currentHour < item.endHour
    );

    return currentSchedule;
  };
  const currentRunningSchedule = getCurrentSchedule();

  const [startHour, setStartHour] = useState<number>(
    currentRunningSchedule?.startHour
  );
  const [endHour, setEndHour] = useState<number>(
    currentRunningSchedule?.endHour
  );

  const getAttempsLeaderboard = async () => {
    firebase
      .firestore()
      .collection("1337winners")
      .onSnapshot((snapshot) => {
        const formattedData = snapshot.docs.map((doc) => {
          const today = new Date(doc.data().winningDate.seconds * 1000);
          const winningDay = fullDays[days[today.getDay()]];
          if (doc?.data()) {
            return {
              ...doc.data(),
              winningDay: winningDay,
            } as LeaderboardItemTypes;
          }
        });

        if (formattedData.length !== 0) {
          setLeaderboardData(
            formattedData.sort((a, b) => a.attempts - b.attempts)
          );
        }
      });
  };

  useEffect(() => {
    const updateSchedule = () => {
      const newSchedule = getCurrentSchedule();
      if (newSchedule) {
        setStartHour(newSchedule.startHour);
        setEndHour(newSchedule.endHour);
      } else {
        setStartHour(null);
        setEndHour(null);
      }
    };

    // Check and update the schedule immediately
    updateSchedule();

    // Set interval to check the schedule every minute
    const intervalId = setInterval(updateSchedule, 15000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getAttempsLeaderboard();

    setInterval(() => {
      getAttempsLeaderboard();
    }, 30000);
  }, []);

  const filterByHour = (data: LeaderboardItemTypes[]) => {
    return data?.filter((user) => {
      console.log(currentRunningSchedule);

      const winningDate = new Date(user.winningDate.seconds * 1000);
      const winningHour = winningDate.getHours();

      return (
        winningHour >= startHour &&
        winningHour <= endHour &&
        user.winningDay === currentRunningSchedule.day
      );
    });
  };

  const hasScore1337 = filterByHour(leaderboardData)
    ?.filter((user) => user.score === 1337)
    .slice(0, 11);

  const honorableMentions = filterByHour(leaderboardData)
    ?.filter((user) => user.score !== 1337)
    .slice(0, 30);

  if (hasScore1337?.length === 0) {
    return (
      <Challenge1337VerticlePageEmpty honorableMembers={honorableMentions} />
    );
  }

  return (
    <div
      id="leet"
      className={`leet ${isLargeVersion ? "leet-lg" : ""}`}
      tabIndex={0}
    >
      <div className="leet-challenge leet-challenge__verticle">
        <div className="leet-challenge__heading leet-challenge__heading--verticle">
          <img src={elgigantenLogo} alt="" />
        </div>
        <div className="leet-challenge__description leet-challenge__description--verticle">
          <p>Win dreamhack coins</p>
          <p>Stop the timer at 1337</p>
        </div>
        <div className="leet-challenge__grid leet-challenge__grid--verticle">
          <div className="leet-challenge__leaderboard">
            <div className="leaderboard">
              <div className="leaderboard__header">
                <p>{i18next.t("leet.leaderboard.username")}</p>
                <p>{i18next.t("leet.leaderboard.attempts")}</p>
              </div>
              {hasScore1337?.length === 0 && (
                <p className="leaderboard__empty">
                  There are currently no winners
                </p>
              )}
              {hasScore1337?.length !== 0 &&
                hasScore1337?.map((doc, index) => (
                  <div className="leaderboard__item" key={index}>
                    <div className="item">
                      <div className="item__user">
                        <LeaderboardUserItem uid={doc.uid} />
                      </div>
                      <div className="item__score">{doc.attempts}</div>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="leet-challenge__honorable leet-challenge__honorable--verticle">
            <div className="honorable">
              {honorableMentions?.length === 0 ? (
                <h2>
                  players that stop the timer close to 1337 are going to appear
                  here!
                </h2>
              ) : (
                <h2>Honorable mentions</h2>
              )}

              <div className="honorable__list honorable__list--verticle">
                {honorableMentions &&
                  honorableMentions.map((doc, index) => (
                    <div className="honorable__item" key={index}>
                      {doc.userName} ({doc.score})
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="leet-challenge__scan-qr-here">
          <div className="scan-qr-here">
            <div className="scan-qr-here__code">
              <QRCode
                value="https://elgiganten.iplay.dk/1337-redirect"
                viewBox={`0 0 336 336`}
              />
            </div>
            <div className="scan-qr-here__arrow">
              <ArrowCurved />
            </div>
            <div className="scan-qr-here__text">
              <p>scan this qr code</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Challenge1337VerticlePage;
