// Todo fields start with DA:

const TRANSLATIONS_DAN = {
  landing: {
    hero: {
      headlineColored: "Udfordre Dine Venner",
      headline: "mens du spiller",
      description: "Track and measure different aspects of popular games while playing. Challenge your friends to most kills in CS2, longest distance kill in PUBG or fastest lap time in F1 2023. Challenges let you decide what to track.",
      cta: "Tilmeld dig nu",
    },
    counter: {
      cta1: "Understøttet computerspil",
      cta2: "Spændende udfordringer",
      cta3: "Forskellige kriterier",
      support: "Understøtter Counter-Strike 2, Fortnite, PUBG, F1 2023, Fall Guys og Forza Motorsport 8 m.fl.",
    },
    heading: {
      headline: "Udforsk dine muligheder",
      description: "Se nye muligheder, mens du spiller. Forbedr dit niveau, dyk ned i en verden af spiludfordringer og konkurrencer på vores platform.",
    },
    cta: {
      challenges: {
        headline: "Udfordringer",
        description: "Er du den bedste gamer blandt dine venner? Find ud af det ved at skabe udfordringer og bestige ranglisterne!",
        button: "Kom i gang",
      },
      experience: {
        headline: "Gaming oplevelse",
        description: "Din præstation vil blive sporet automatisk af vores Game Tracker. Efter en engangsopsætning vil du være klar til at konkurrere i alle spil!",
        button: "Get the tracker",
      },
      social: {
        headline: "Socialt fællesskab",
        description: "Opret forbindelse til dine venner, eller tiltræk nye følgere, mens du poster kvalitetsspilindhold på dit feed. Tilpas din profil, del episke øjeblikke og byg dit eget fællesskab.",
        button: "Opret din profil",
      },
      about: {
        headline: "Om os",
        description: "Vi er en gruppe spillere, der kan lide at konkurrere. World of Gamers er en platform, der gør spil sjovere!",
        button: "Læs mere",
      },
    }
  },
  // Footer
  footer: {
    alert: {
      headline: "Klar til at komme i gang?",
      description: "Download vores Windows App og indtag førstepladserne i dag!",
      button: "Hent Windows App",
      link: "Læs mere",
    }
  },
  cookies: {
    headline: "Cookies",
    description: "Vi bruger cookies for at give dig den bedst mulige oplevelse på vores hjemmeside.",
    more: "Du kan læse mere om, hvilke cookies vi bruger i vores",
    link: "Privatlivspolitik",
    essential: "Funktionelle cookies: Disse cookies er nødvendige for, at vores websted fungerer efter hensigten.",
    optional: "Valgrie cookies: Vi forbeholder os retten til at bruge disse cookies for at få en bedre forståelse af vores brugere og hvordan de bruger vores hjemmeside.",
    button: {
      accept: "Accepter alle cookies",
      decline: "Afvis valgfrie cookies",
    },
  },
  leet: {
    headline: "Elgiganten 1337 Challenge",
    objective1: "Kan du lande på",
    objective2: "ved at trykke på knappen?",
    totalAttempts: "Forsøg i alt",
    start: "Start",
    stop: "Stop",
    youWon: "Du har vundet!",
    congrats: "Tillykke! Godt gået!",
    nextStep: "Vis dette til en Elgiganten medarbejder i butikken",
    leaderboard: {
      headline: "Vindere",
      date: "Dato",
      username: "Brugernavn",
      attempts: "Forsøg",
    },
    toast: {
      success: "Du er tilføjet til listen med vindere",
    },
  },
  countdown: {
    endsIn: "slutter om",
    hasEnded: "er afsluttet",
    days: {
      plural: "dage",
      singular: "dag",
    },
    hours: {
      plural: "timer",
      singular: "time",
    },
    minutes: {
      plural: "minutter",
      singular: "minut",
    },
    seconds: {
      plural: "sekunder",
      singular: "sekund",
    },
  },
  days: {
    0: "Søn", 
    1: "Man", 
    2: "Tirs", 
    3: "Ons", 
    4: "Tors", 
    5: "Fre", 
    6: "Lør"
  },
  product: {
    discount: "Spar",
    btn: {
      label: "Se produktet",
    }
  },
  settings: {
    tabs: {
      yourInfo: "Dine oplysninger",
      profileImage: "Profilbillede",
      connections: "Forbindelser",
      themes: "Temaer",
      preferences: "Præferencer",
      changePassword: "Skift adgangskode",
      otherSettings: "Andet",
      language: "Sprog",
    },
    password_reset: {
      head: "Opdater din adgangskode",
      steps: {
        1: "Indtast din e-mail adresse for at modtage en 6-cifret kode.",
        2: "Indtast den 6-cifret nulstillingskode.",
        3: "Indtast en nyt adgangskode.",
        4: "Gentag din nye adgangskode.",
      },
    },
  },

  // Feed
  feed: {
    welcome_msg: "Velkommen tilbage, ",
  },

  // Translated from top until here -----
  profile: {
    tabs: {
      feed: "Væg",
      challenges: "Udfordringer",
      community: "Fællesskab",
      gathers: "Gathers",
      teams: "Hold",
      achievements: "Achievements",
    },
    challenges: {
      badges: {
        all: "Alle",
        participatingIn: "Deltager i",
        createdBy: "Oprettet af",
        favorites: "Favoritter",
        private: "Privat",
        completed: "Afsluttet",
        drafts: "Kladder",
      },
    },
    community: {
      heading: "fællesskab",
      tabs: {
        friends_singular: "ven",
        friends_plural: "venner",
        following_singular: "følges af",
        following_plural: "følgere",
        followers_singular: "følger",
        followers_plural: "følger",
        empty_string1: "har ikke nogle", // {userName} does not have any {currentTab} yet.
        empty_string2: "endnu", // {userName} does not have any {currentTab} yet.
        following_empty_string1: "har ikke nogle", // {userName} does not have a {currentTab} yet,
        following_empty_string2: "endnu", // {userName} does not have a {currentTab} yet.
      },
      search: {
        placeholder: "Søg efter brugernavn",
      }
    },
    friends: {
      title: "Venner",
      no_friends_msg: "Denne bruger har ingen venner endnu.",
    },
    edit: {
      title: "Rediger profil",
      tabs: {
        Avatar: "Avatar",
        Information: "Information",
        Connections: "Forbindelser",
        Themes: "Temaer",
      },
      avatar: {
        edit_msg: "Skift avatar",
        upload_msg:
          "Upload et billede eller vælg et af vores predefinerede avatarer.",
        upload_title: "Upload avatar",
      },
      connections: {
        title: "Tilføj konti til din profil",
        // In between desc1 and desc2 is the brands name
        desc1: "Forbind din", // NEW
        desc2:
          "profil med tredje-parter for at deltage i aktiviteter såsom Udfordringer. Du kan altid fjerne forbindelsen igen. Adgangskoder og betalingsinfo vil aldrig blive delt.", // NEW
        no_connections: "Ingen forbindelser", // NEW
        // In between desc1 and desc2 is the brands name
        no_connections_desc1: "Lås op", // NEW
        no_connections_desc2: "for alle funktioner ved at forbinde dine konti", // NEW
      },
      themes: {
        choose_msg:
          "Vælg et tema og slutt dig til en af ​​vores partnere i deres zone for at få premium funktioner og aktiviteter gratis",
      },
    },
    actions: {
      add_friend: "Tilføj ven",
      unfriend: "Fjern ven",
      cancel_friend_request: "Annuller venneanmodning",
      accept_friend_request: "Accepter venneanmodning",
      decline_friend_request: "Afvis venneanmodning",
      follow: "Følg",
      unfollow: "Stop med at følge",
    }
  },
  // Overview archive (overview page banners + filters)| news, challenges, teams, gathers
  overview: {
    news: {
      create_btn: "Opret ny artikel",
      desc: "Senest nyt fra World of Gamers og gaming industrien.",
      featured: "Fremhævede artikler",
      headline: {
        mine: "Mine artikler",
        editorial: "Redaktionel artikler",
        popular: "Mest populære",
        community: "Artikler fra fællesskabet",
      },
      badges: {
        mine: "Mine",
        editorial: "Redaktionel",
        popular: "Mest populære",
        community: "Artikler fra fællesskabet",
      },
    },
    challenges: {
      create_btn: "Opret en udfordring",
      desc: "Definér hvordan du vil spille!",
      headline: {
        mine: "Mine udfordringer",
        new: "Udforsk",
        zone: "Zone aktivitet",
      },
      badges: {
        mine: "Mine",
        new: "Udforsk",
        zone: "Zone aktivitet",
      },
    },
    teams: {
      create_btn: "Opret et hold",
      desc: "DA Come together as a team to achieve great things!",
      headline: {
        mine: "Mine hold",
        new: "Udforsk",
      },
      badges: {
        mine: "Mine",
        new: "Udforsk",
      },
    },
    gathers: {
      create_btn: "Opret event",
      desc: "Saml dine venner eller dan nye venskaber!",
      headline: {
        mine: "Mine events",
        new: "Udforsk",
      },
      badges: {
        mine: "Mine",
        new: "Udforsk",
      },
    },
  },

  // About page
  about: {
    title: "About World of Gamers",
    desc: "World of Gamers is a global community platform where gamers engage with other gamers as well as well-known brands. We wish to enable gamers to engage with each other through several means. As a platform we provide rich functionality to support the needs of the individual gamer and organizations while offering a unique possibility for our cooperative partners to engage with their intended key audience.",
    subsection1: {
      header: "DA What we do",
      desc1:
        "DA World of Gamers (“WOG”) is a gaming community that carries the DNA of more than 15 years of shaping eSport in Denmark and the Nordics.",
      desc2:
        "DA WOG is a gaming community with regional activities. We are using the gamiDAcation of statistics to activate our user and give them the option to create content for the whole community in a easy and accessible manner.",
    },
    subsection2: {
      header: "DA Who we are",
      desc1:
        "DA We are a team of passionate gamers who played our role in creating a strong foundation for eSport and raising the bar.",
      desc2:
        "DA World of Gamers are based on the Nordic model with good values and ethics, and is born out of our passion for gaming and our ambition to create something unique that has the potential to elevate gaming and eSport and set new standards.",
    },
    subheader2: "DA Who we are",
    subheader3: "DA The Nordic model",

    list: {
      1: "DA Bringing people together physically and/or digitally",
      2: "DA Uniting people based on a healthy and responsible community",
      3: "DA The ability for all participants to grow and develop their skills",
      4: "DA Providing safe and good experiences for all participants",
      5: "DA Uniting participants regardless of age, gender, and social background",
      6: "DA Contributing to giving participants increased value in their life",
      7: "DA Good values and ethics by treating fellow participants nicely",
      8: "DA Promoting happiness and passion through all activities",
    },
  },

  // Not found
  not_found: {
    title: "404 Siden blev ikke fundet",
    desc: "Denne side eksisterer ikke, eller er ikke længere tilgængelig.",
    button: "Gå tilbage til WOG",
  },

  // help page
  help: {
    title: "DA Feedback",
    general_issues: {
      title: "DA General issues",
      desc: "DA Report issues that appeared during interacting with website:",
      list: {
        0: "DA using search DAeld",
        1: "DA create button",
        2: "DA navigation, etc.",
      },
    },
    sign_up: {
      title: "DA Sign up process",
      desc: "DA Report issues on creating an account: ",
      list: {
        0: "DA performance",
        1: "DA occuring errors",
        2: "DA problems connecting through Battle.net or Steam platform, etc.",
      },
    },
    profile: {
      title: "DA Profile page",
      desc: "DA Report issues on profile page: ",
      list: {
        0: "DA connecting games account",
        1: "DA adding to friends",
        2: "DA editing profile",
        3: "DA notiDAcations, etc.",
      },
    },
    create_challenge: {
      title: "DA Create a challenge",
      desc: "DA Report issues that appeared while creating a challenge: ",
      list: {
        0: "DA text formatting",
        1: "DA setting the dates",
        2: "DA choosing criterias, etc.",
      },
    },
    single_challenge: {
      title: "DA Single challenge",
      desc: "DA Report issues that appeared during interacting with single challenge page:  ",
      list: {
        0: "DA incorrect leaderboard",
        1: "DA wrong data",
        2: "DA participating in a challenge, etc.",
      },
    },
    challenge_overview: {
      title: "DA Challenges overview",
      desc: "DA Report issues that appeared during interacting with challenges overview page: ",
      list: {
        0: "DA incorrect leaderboard",
        1: "DA wrong data",
        2: "DA participating in a challenge, etc.",
      },
    },
  },
  // privacy policy
  privacy_policy: {},

  // terms of service
  term_of_service: {},

  // Sign up
  auth: {
    authcard: {
      unregistered: "Er du ikke tilmeldt endnu?",
      create: "Opret en konto",
      registered: "Er du allerede tilmeldt?",
      login: "Log ind",
      verify_email: "Vi har sendt en email med instruktioner til din e-mail:",
      forgot_password: "Indtast din e-mail du brugte til at tilmelde dig med, og vi sender dig en nulstillingskode.",
      or: "eller",
    },
    login: {
      headline: "Log ind på din bruger",
      forgot_password: "Glemt din adgangskode?"
    },
    create: {
      headline: "Opret en ny bruger",
      password: {
        headline: "Password must consist of at least",
        characters: "min. 8 tegn",
        uppercase: "et stort bogstav",
        lowercase: "et lille bogstav",
        special: "et speciel tegn",
        number: "mindst et nummer",
        match: "adgangskoderne er ens",
      },
      profile: {
        headline: "Næsten færdig",
        input: {
          username: {
            label: "Vælg et unikt brugernavn",
            placeholder: "Indtast et brugernavn",
          },
        },
      },
      error: {
        invalid: "E-mailen er ikke gyldig",
      }
    },
    forgot: {
      headline: "Glemt adgangskode",
      error: {
        invalid: "E-mailen er ikke gyldig",
      }
    },
    verify: {
      headline: "Bekræft din e-mail adresse",
      validity: "Koden er gyldig i 10 minutter efter afsendelse. Hvis koden ikke virker, kan du anmode om en ny her:",
    },
    reset: {
      headline: "Nulstil din adgangskode",
      code: "Kode fra e-mail",
      digits: "Indtast den 6-cifret kode sendt til",
    },
    input: {
      email: {
        label: "Din e-mail adresse",
        placeholder: "F.eks. email@gmail.com",
      },
      username: {
        label: "E-mail eller brugernavn",
        placeholder: "Indtast din e-mail adresse eller brugernavn",
      },
      password: {
        label: "Adgangskode",
        placeholder: "Indtast din adgangskode",
        create: {
          label: "Vælg adgangskode",
          placeholder: "Vælg en unik adgangskode",
          error: "Adgangskoden lever ikke op til kravene",
        },
        repeat: {
          label: "Gentag adgangskode",
          placeholder: "Skriv den samme adgangskode igen",
          error: "Adgangskoderne er ikke ens"
        },
      },
      button: {
        login: "Log ind",
        create: "Opret bruger",
        reset: "Nulstil adgangskode",
        send_link: "Send link",
        cancel: "Annuller",
        quick_account: "Hurtig konto",
        request: "Anmod om en ny kode",
      }
    },
    terms: {
      1: "Ved at fortsætte bekræfter du vores",
      2: "Vilkår for brug",
      3: "og",
      4: "Privatlivspolitik",
      5: "samt bekræfter at du er mindst 13 år gammel",
    },
    toast: {
      password: {
        wrong: "Adgangskoden er forkert eller måske er dette ikke en bruger med en adgangskode.",
      },
      profile: {
        incomplete: "Din profil er ikke færdig. Udfyld resten for at færdiggøre din profil.",
      },
    },
    log_into: "Log ind i", // Will have the set brand name after "to"
    log_in_how: "Hvordan vil du gerne logge ind?",
    sign_up_how: "Hvordan vil du gerne oprette en bruger?",
    create_profile: "Opret din profil", // NEW
    // auth step 2
    small_info:
      "du kan altid ændre dit brugernavn, profilbillede og opdatere dine præferencer under dine profilindstillinger",
    pick_username: "Vælg et brugernavn",
    profile_image: "Profilbillede",
    personalize: {
      small: "Personliggør",
      large: "Personliggør din brugerprofil",
    },
    // ----
    // auth step 3
    all_set: "Nu er du klar!",
    start_info:
      "Start din rejse ved at forbinde dine spilkonti eller ved at udforske de forskellige udfordringer.",
    // ----
  },

  // Basic Buttons text. other btn text's will be replaced with these for convenience
  //  comments next to it is where where specific ones can be found
  // words that are all capital should be capitalized in scss, not here.
  buttons: {
    upload: "Upload",
    save: "Gem",
    save_changes: "Gem ændringer",
    cancel: "Annuller",
    apply_theme: "Skift", // Theme picker
    remove_avatar: "Fjern billede", // Profile
    add_friend: "Tilføj ven", // Profile
    unfriend: "Fjern ven", // Profile
    manage_btn: "Rediger",
    follow: "Følg",
    unFollow: "Stop med at følge",
    log_in: "Log ind",
    log_out: "Log ud", // Navigation
    create: "Opret", // Navigation
    see_more: "Se mere", // Home
    go_back: "Gå tilbage", // Home
    join: "Tilslut", // Challenges cards
    get_started: "Kom i gang",
    try_btn: "Prøv nu",
    ready: "Klar", // sign up 2
    connect_accounts: "Forbind konti", // sign up 3
    explore_challenges: "Udforsk udfordringer",
    explore_teams: "Udforsk hold",
    skip_to_home: "Gå til startside", // sign up 3
    remove_btn: "Fjern billede",
  },
  // Strings used globaly
  strings: {
    step: "Trin", // `Step` x of x
    of: "af", // Step x `of` x

    // Deature names, navigation, carousels etc
    challenges: "Udfordringer",
    challenge: "", // non uppercase
    teams: "Hold",
    team: "", // non uppercase
    news: "Nyheder",
    gathers: "Events",
    gather: "", // non uppercase
    feed: "Væg",
    friends: "Venner",
    friend: "", // non uppercase
    messages: "Beskeder",
    zones: "Zoner",
    participants: "DA: Participants", // Gathers
    interested: "DA: Interested", // Gathers
    you_havent: "Du har ikke", // Feed
    user_hasnt: "Denne bruger har ikke", // Feed
    shared_anything: "delt noget endnu", // Feed
    share_something_friends: "Del noget med dine venner...", // Placeholder-inputs
    // Will have another word like "team, challenge, news" etc at the end
    share_something_about: "DA Share something about this", // Placeholder-inputs
    share_about_yourself: "Del lidt om dig selv", // profile desc placeholder
    user_name: "Brugernavn",
    user_name_placeholder: "Vælg et brugernavn",
    full_name: "For- og efternavn",
    full_name_placeholder: "Fortæl os hvad du hedder",
    about: "Om dig",
    about_placeholder: "Fortæl os lidt om dig selv",
    upload_avatar: "Opload profilbillede",
    send_password_reset_code: "Send nulstillingskode",
  },
  // Single challenge
  challenge: {
    starts: "Starter om",
    ends: "Slutter om",
    leaderboard: {
      headline: "Rangliste",
      tabs: {
        ALL: "Alle",
        FRIENDS: "Venner",
        FOLLOWING: "Følger",
      },
      score: {
        yours: "Din score",
        description: "Dit resultat vises her, så snart du deltager",
        noParticipants: "Vær den første til at deltage og inviter dine venner",
        noFriends: "Du har ikke nogen venner, der aktivt deltager i denne udfordring endnu",
        noFollowing: "Du følger ikke nogen på denne konto endnu",
      }
    },
    buttons: {
      tabs: {
        back: "Tilbage til alle",
      },
      join: "Deltag",
      joinThisChallenge: "Deltag i udfordring",
      joined: "Deltager",
      submit: "Indsend resultat",
    },
    criteria: {
      headline: "Hvordan beregnes scoren"
    },
    terms: {
      heading: "Vilkår",
      preBrand: "Du skal være til stede ved eventet og møde op ved ",
      postBrand: "standen den dag, du har vundet, og inden for åbningstiderne for at kunne kræve din præmie. Hvis du ikke kan møde op, kan vi desværre ikke tildele dig din ellers velfortjente præmie. Den vil i stedet blive tildelt den næste spiller på ranglisten.",
    },
    participants: {
      participating: "deltager",
      and: "og",
      more: "flere",
    },
    disclaimers: {
      CSGO: "CS2 er et registreret varemærke tilhørende Valve. Varemærker tilhører deres respektive ejere. Valve har ophavsret på spilmaterialet. Valve har ikke godkendt og er ikke ansvarlig for dette websted eller dets indhold.",
      Dota2: "Dota2 er et registreret varemærke tilhørende Valve. Varemærker tilhører deres respektive ejere. Valve har ophavsret på spilmaterialet. Valve har ikke godkendt og er ikke ansvarlig for dette websted eller dets indhold.",
      PUBG: "PUBG er et registreret varemærke tilhørende PUBG Corporation. Varemærker tilhører deres respektive ejere. PUBG Corporation har ophavsret på spilmaterialet. PUBG Corporation har ikke godkendt og er ikke ansvarlig for dette websted eller dets indhold.",
      f12022: "F1® 22 Game er et officielt produkt fra FIA FORMULA ONE WORLD CHAMPIONSHIP. EA, EA SPORTS, EA SPORTS-logoet, Codemasters, EGO og Codemasters-logoet er varemærker tilhørende Electronic Arts Inc. Varemærker tilhører deres respektive ejere. Electronic Arts Inc. har ikke godkendt og er ikke ansvarlig for dette websted eller dets indhold.",
      f12023: "F1® 23 Game er et officielt produkt fra FIA FORMULA ONE WORLD CHAMPIONSHIP. EA, EA SPORTS, EA SPORTS-logoet, Codemasters, EGO og Codemasters-logoet er varemærker tilhørende Electronic Arts Inc. Varemærker tilhører deres respektive ejere. Electronic Arts Inc. har ikke godkendt og er ikke ansvarlig for dette websted eller dets indhold.",
      fallguys: "Fall Guys er et registreret varemærke tilhørende Mediatonic Limited. Varemærker tilhører deres respektive ejere. Mediatonic Limited har ophavsret på spilmaterialet. Mediatonic Limited har ikke godkendt og er ikke ansvarlig for dette websted eller dets indhold.",
      fh5: "Forza Horizon er et registreret varemærke tilhørende Microsoft Studios. Varemærker tilhører deres respektive ejere. Microsoft Studios har ophavsret på spilmaterialet. Microsoft Studios har ikke godkendt og er ikke ansvarlig for dette websted eller dets indhold.",
      fm8: "Forza Motorsport er et registreret varemærke tilhørende Microsoft Studios. Varemærker tilhører deres respektive ejere. Microsoft Studios har ophavsret på spilmaterialet. Microsoft Studios har ikke godkendt og er ikke ansvarlig for dette websted eller dets indhold.",
    },
  }
};

export default TRANSLATIONS_DAN;
