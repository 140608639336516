import LogitechLogoSmall from "../assets/logitech/logitech-g-sm.svg"
import LogitechLogoLarge from "../assets/logitech/logitechg.svg"
import TCLLogo from "../assets/tcl/tcl-logo.svg";
import PCSpecialistLogoLarge from "../assets/pcspecialist/pcs-logo-main.svg";
import PCSpecialistLogoSmall from "../assets/pcspecialist/pcs-logo-main_small.svg";
import JBLLogoLarge from "../assets/jbl-quantum/jbl-quantum-logo_white.svg";
import JBLLogoSmall from "../assets/jbl-quantum/quantum_logo.svg";

export const partnerRoute = {
    "/partners/logitech": {
        partner: "LOGITECH",
        route: "/partners/logitech",
        theme: "theme-logitech",
        logo: {
            small: LogitechLogoSmall,
            large: LogitechLogoLarge,
        },
    },
    "/partners/tcl": {
        partner: "TCL",
        route: "/partners/tcl",
        theme: "theme-tcl",
        logo: {
            small: TCLLogo,
            large: TCLLogo,
        },
    },
    "/partners/pcspecialist": {
        partner: "PCSPECIALIST",
        route: "/partners/pcspecialist",
        theme: "theme-pcspecialist",
        logo: {
            small: PCSpecialistLogoSmall,
            large: PCSpecialistLogoLarge,
        },
    },
    "/partners/jbl": {
        partner: "JBL",
        route: "/partners/jbl",
        theme: "theme-jbl",
        logo: {
            small: JBLLogoSmall,
            large: JBLLogoLarge,
        },
    }
}