import i18next from "i18next";
import { FC } from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";

interface ConsentProps {
  layout?: "default" | "small";
}

const Consent: FC<ConsentProps> = (
  layout,
): JSX.Element => {
  const isSmall = window.location.pathname === "/" || 
  window.location.pathname === "/landing" ||
  window.location.pathname === "/login" ||
  window.location.pathname === "/enter-username" ||
  window.location.pathname === "/forgot-password" ||
  window.location.pathname === "/verify-email" ||
  window.location.pathname === "/signup" ||
  window.location.pathname === "/password-reset" ||
  layout.layout === "small";

  const layoutClass = layout ? `CookieConsent__layout CookieConsent__layout--${layout.layout}` : "";
  const actionClass = layout ? `CookieConsent__actions--${layout.layout}` : "";
  const btnClass = layout ? `CookieConsent__btn--${layout.layout}` : "";
  const bodyClass = layout ? `CookieConsent__body--${layout.layout}` : "";

  return (
    <CookieConsent
        location="none"
        buttonText={i18next.t("cookies.button.accept")}
        expires={90}
        enableDeclineButton
        declineButtonText={i18next.t("cookies.button.decline")}
        disableStyles={true}
        containerClasses={"CookieConsent " + layoutClass}
        contentClasses={"CookieConsent__body " + bodyClass}
        overlayClasses="CookieConsent__overlay"
        buttonWrapperClasses={"CookieConsent__actions " + actionClass}
        declineButtonClasses={"CookieConsent__btn CookieConsent__btn--decline " + btnClass}
        buttonClasses={"CookieConsent__btn CookieConsent__btn--accept " + btnClass}
        overlay={!isSmall}
        // debug={true}
      >
        <h2>{i18next.t("cookies.headline")}</h2>
        <p>
        {i18next.t("cookies.description")}
        {!isSmall && (
            <ul>
                <li>
                {i18next.t("cookies.essential")}
                </li>
                <li>
                {i18next.t("cookies.optional")}
                </li>
            </ul>
        )}
        {" "}{i18next.t("cookies.more")} <Link to="/privacy-policy">{i18next.t("cookies.link")}</Link>
        </p>
      </CookieConsent>
  );
};

export default Consent;