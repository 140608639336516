import React, { FC } from "react";

export const EventSingleChallengeDetails: FC<{
    goal: string;
    description: string;
    game: string;
}> = ({
    goal,
    description,
    game,
}): JSX.Element => {  

    return (
        <> 
            <h2>{game} challenge</h2>
            <h3>What to do</h3>
            <p>
                {goal}
            </p>
            <h3>How we calculate your score</h3>
            <p>
                {description}
            </p>  
        </>
    );
};