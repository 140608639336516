import { Countdown } from "@/components/Countdown";
import React, { FC, useEffect, useState } from "react";

export const EventSingleChallengeHead: FC<{
    title: string;
    startDate: {
        seconds: number,
        nanoseconds: number,
    };
    endDate: {
        seconds: number,
        nanoseconds: number,
    };
}> = ({
    title,
    startDate,
    endDate
}): JSX.Element => {  

    return (
        <div className="activity__head">
            <div className="activity__title">
                <h1>{title}</h1>
            </div>
            <div className="activity__countdown">
                <Countdown 
                    type="challenge"
                    startDate={startDate} 
                    endDate={endDate} 
                />
            </div>
        </div>
    );
};