import i18next from "i18next";
import React from "react";
import elgigantenLogo from "../../../branchAndBrandSpecific/utils/brandVariables/assets/elgiganten/elgiganten.svg";
import Coinsbg from "./assets/Coinsbg.png";
import QRCode from "react-qr-code";

export const Challenge1337VerticlePageEmpty = ({
  honorableMembers,
}: {
  honorableMembers: any;
}) => {
  console.log(honorableMembers);

  const hasHonorableMembers = honorableMembers.length !== 0;
  return (
    <div id="leet" className={`leet`} tabIndex={0}>
      <div className="leet__coinsbg" />
      <div className="leet-challenge leet-challenge__verticle leet-challenge__empty">
        <div className="leet-challenge__heading leet-challenge__heading--verticle">
          <img src={elgigantenLogo} alt="" />
        </div>

        <div className="leet-challenge__empty-grid">
          <div className="leet-challenge__description leet-challenge__description--verticle">
            {hasHonorableMembers ? (
              <>
                <p>be the first to Win </p>
                <p>dreamhack coins</p>
              </>
            ) : (
              <>
                <p>Win</p>
                <p>dreamhack coins</p>
              </>
            )}
          </div>

          <div className="leet__qrcode">
            <QRCode
              value="https://elgiganten.iplay.dk/1337-redirect"
              // style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              viewBox={`0 0 225 225`}
            />
          </div>

          <div className="leet-challenge__description leet-challenge__description--verticle">
            <p>scan the qr code </p>
            <p>and</p>
            <p>Stop the timer at 1337</p>
          </div>

          {hasHonorableMembers && (
            <div className="leet-challenge__honorable leet-challenge__honorable--empty-page">
              <div className="honorable">
                <h2>Honorable mentions</h2>
                <div className="honorable__list honorable__list--verticle">
                  {honorableMembers &&
                    honorableMembers.map((doc, index) => (
                      <div className="honorable__item" key={index}>
                        {doc.userName} ({doc.score})
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
