import i18next from "i18next";
import React, { FC, useEffect, useState } from "react";

export const Countdown: FC<{
    type: string,
    startDate: {
        seconds: number,
        nanoseconds: number,
    };
    endDate: {
        seconds: number,
        nanoseconds: number,
    };
}> = ({
    type,
    endDate
}): JSX.Element => {  

    const calculateTimeLeft = () => {
        const endDateInMilliseconds = endDate.seconds * 1000;
        const nowInMilliseconds = Date.now();

        const differenceInMilliseconds = endDateInMilliseconds - nowInMilliseconds;

        if (differenceInMilliseconds < 0) {
            return null;
        }
        
        const days = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
        const hours = Math.floor((differenceInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((differenceInMilliseconds % (1000 * 60)) / 1000);

        return { days, hours, minutes, seconds };
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, [endDate]);

    // Variables
    const moreThanOneDay = timeLeft !== null && timeLeft.days > 0;
    const bodyClasses = moreThanOneDay ? "countdown__body" : "countdown__body countdown__body--3col";

    return (
        <div className="countdown">
            <div className="countdown__heading">
                <h2>
                    {timeLeft !== null ? `${type} ${i18next.t("countdown.endsIn")}` : `${type} ${i18next.t("countdown.hasEnded")}`}
                </h2>
            </div>
            {timeLeft !== null && (
            <div className="countdown__content">
                <div className="countdown__time">
                    <div className={bodyClasses}>
                        {moreThanOneDay && 
                            <div className="countdown__item">
                                <div className="countdown__number">{timeLeft.days}</div>
                                <div className="countdown__label">
                                    {timeLeft.days === 1 ?
                                        i18next.t("countdown.days.singular") :
                                        i18next.t("countdown.days.plural")
                                    }
                                </div>
                                <div className="countdown__separator">:</div>
                            </div>
                        }
                        <div className="countdown__item">
                            <div className="countdown__number">
                                {timeLeft.hours < 10 ? `0${timeLeft.hours}` : timeLeft.hours}
                            </div>
                            <div className="countdown__label">
                                {timeLeft.hours === 1 ? 
                                    i18next.t("countdown.hours.singular") :
                                    i18next.t("countdown.hours.plural")
                                }
                            </div>
                            <div className="countdown__separator">:</div>
                        </div>
                        <div className="countdown__item">
                            <div className="countdown__number">
                                {timeLeft.minutes < 10 ? `0${timeLeft.minutes}` : timeLeft.minutes}
                            </div>
                            <div className="countdown__label">
                                {timeLeft.minutes === 1 ?
                                    i18next.t("countdown.minutes.singular") :
                                    i18next.t("countdown.minutes.plural")
                                }    
                            </div>
                            <div className="countdown__separator">:</div>
                        </div>
                        <div className="countdown__item">
                            <div className="countdown__number">
                                {timeLeft.seconds < 10 ? `0${timeLeft.seconds}` : timeLeft.seconds}
                            </div>
                            <div className="countdown__label">
                                {timeLeft.seconds === 1 ?
                                    i18next.t("countdown.seconds.singular") :
                                    i18next.t("countdown.seconds.plural")
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )}
        </div>
    );
};