import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import FeedFetcher from "../components/Feed.fetcher";
import SuggestedFriends from "../components/SuggestedFriends/SuggestedFriends";
import useDocumentTitle from "@/hooks/useDocumentTitle";

export default function FeedPage() {
  // Page Document Title
  useDocumentTitle("Feed");

  return (
    <div className="feed__grid">
      <div>
        <FeedFetcher />
      </div>
      <div className="feed__suggestions">
        <div className="feed__friends">
          <SuggestedFriends />
        </div>
      </div>
    </div>
  );
}
