import i18next from "i18next";
import React from "react";

interface Props {
    game: string,
}

const ChallengeDisclaimer: React.FC<Props> = ({
  game,
}) => {

    const disclaimers = {
        CSGO: i18next.t("challenge.disclaimers.CSGO"),
        Dota2: i18next.t("challenge.disclaimers.Dota2"),
        PUBG: i18next.t("challenge.disclaimers.PUBG"),
        f12022: i18next.t("challenge.disclaimers.f12022"),
        f12023: i18next.t("challenge.disclaimers.f12023"),
        fallguys: i18next.t("challenge.disclaimers.fallguys"),
        fh5: i18next.t("challenge.disclaimers.fh5"),
        fm8: i18next.t("challenge.disclaimers.fm8"),
    }

    if (disclaimers[game]) {
        return (
            <div className="challenge__disclaimer">
                <p>
                    {disclaimers[game]}
                </p> 
            </div>
        )
    }
    
    return (
        <></ >
    );
};

export default ChallengeDisclaimer;