import UnAuthorizedLayout from "../../../../branchAndBrandSpecific/layout/UnAuthorizedLayout";
import { FC, useEffect, useState } from "react";
import "../styles/index.scss";
import { AuthCard } from "../components/AuthCard";
import { BaseTextInput } from "@/components/BaseTextInput/BaseTextInput";
import { ReactComponent as IconCheck } from "../assets/iconCheck.svg";
import { ReactComponent as IconCross } from "../assets/iconCross.svg";
import { Button, Spinner } from "@/components";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import {
  AuthenticationProps,
  AuthenticationResponse,
} from "../types/authenticationTypes";
import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { createToast } from "@/utils/toaster/createToast";
import { useNavigate } from "react-router-dom";
import { FirebaseAuthMethods } from "../components/FirebaseAuthMethods";
import useDocumentTitle from "@/hooks/useDocumentTitle";
import i18next from "i18next";
import { authCredentialsAtom } from "@/utils/atoms/atomClient";
import { useSetAtom } from "jotai";

export const Signup: FC<{}> = () => {
  const navigate = useNavigate();
  const [userCredentials, setUserCredentials] = useState<{
    email: string;
    password: string;
    verifPassword: string;
  }>({
    email: "",
    password: "",
    verifPassword: "",
  });

  const baseAuthenticationProps = {
    method: "email",
    emailAddress: userCredentials.email,
    platform: brand.brandValue,
  } as AuthenticationProps;
  const setAtomCredentials = useSetAtom(authCredentialsAtom);

  //   Checks
  const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userCredentials.email);
  const isPasswordValid =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!]).{8,}$/.test(
      userCredentials.password
    ); // Check if password contains every rule
  const passwordsMatch =
    userCredentials.password === userCredentials.verifPassword;
  const passwordRules = [
    {
      rule: i18next.t("auth.create.password.characters"),
      isValid: userCredentials.password.length >= 8,
    },
    {
      rule: i18next.t("auth.create.password.uppercase"),
      isValid: /^(?=.*[A-Z])/.test(userCredentials.password),
    },
    {
      rule: i18next.t("auth.create.password.number"),
      isValid: /^(?=.*\d)/.test(userCredentials.password),
    },
    {
      rule: i18next.t("auth.create.password.lowercase"),
      isValid: /^(?=.*[a-z])/.test(userCredentials.password),
    },
    {
      rule: i18next.t("auth.create.password.match"),
      isValid:
        userCredentials.password !== "" &&
        userCredentials.password === userCredentials.verifPassword,
    },
    {
      rule: i18next.t("auth.create.password.special"),
      isValid: /[@#$%^&+=!]/.test(userCredentials.password),
    },
  ];
  //

  const setFieldValue = async (targetName: string, value: any) => {
    return setUserCredentials((prev: any) => ({
      ...prev,
      [targetName]: value,
    }));
  };

  // Auth logic
  const [response, _loading, _error, authenticate] =
    useCloudFunctionCall<AuthenticationResponse>(
      "userAuthentication",
      undefined
    );

  useEffect(() => {
    if (response && userCredentials.email) {
      if (response.status === "email-verification-sent") {
        setAtomCredentials({
          email: userCredentials.email,
          password: userCredentials.password,
        });
        localStorage.setItem("authEmailLocal", userCredentials.email);
        createToast({ type: "success", message: response.payload });
        return navigate(response.redirectURL);
      }

      if (
        response.status === "weak-password" ||
        response.status === "email-already-taken" ||
        response.status === "failed-to-create-user"
      ) {
        console.log(response);

        return createToast({ type: "error", message: response.payload });
      }
    }
  }, [response]);

  const handleNextStep = async () => {
    await authenticate({
      ...baseAuthenticationProps,
      action: "signup",
      password: userCredentials.password,
    });
  };

  // Page Document Title
  useDocumentTitle(i18next.t("auth.create.headline"));

  return (
    <UnAuthorizedLayout>
      <div className="authentication__signup">
        <div className="signup">
          <AuthCard title={i18next.t("auth.create.headline")} type="signup">
            {/* Email */}
            <div className="input">
              <BaseTextInput
                label={i18next.t("auth.input.email.label")}
                textType="text"
                value={userCredentials?.email}
                placeholder={i18next.t("auth.input.email.placeholder")}
                setObjectState={setFieldValue}
                setObjectStateTargetName="email"
                error={
                  userCredentials.email &&
                  !isEmailValid &&
                  i18next.t("auth.create.error.invalid")
                }
                functionOnEnter={async () => {
                  if (
                    isEmailValid &&
                    userCredentials.password &&
                    userCredentials.verifPassword
                  ) {
                    handleNextStep();
                  }
                }}
              />
            </div>

            {/* Password */}
            <div className="input">
              <BaseTextInput
                label={i18next.t("auth.input.password.create.label")}
                textType="password"
                value={userCredentials?.password}
                placeholder={i18next.t(
                  "auth.input.password.create.placeholder"
                )}
                setObjectState={setFieldValue}
                setObjectStateTargetName="password"
                error={
                  userCredentials.password &&
                  !isPasswordValid &&
                  i18next.t("auth.input.password.create.error")
                }
                functionOnEnter={async () => {
                  if (
                    isEmailValid &&
                    userCredentials.password &&
                    userCredentials.verifPassword
                  ) {
                    handleNextStep();
                  }
                }}
              />
            </div>

            {/* Verif password */}

            {userCredentials.password && isPasswordValid && (
              <div className="input">
                <BaseTextInput
                  label={i18next.t("auth.input.password.repeat.label")}
                  textType="password"
                  value={userCredentials?.verifPassword}
                  placeholder={i18next.t(
                    "auth.input.password.repeat.placeholder"
                  )}
                  setObjectState={setFieldValue}
                  setObjectStateTargetName="verifPassword"
                  error={
                    userCredentials?.verifPassword &&
                    !passwordsMatch &&
                    i18next.t("auth.input.password.repeat.error")
                  }
                  functionOnEnter={async () => {
                    if (
                      isEmailValid &&
                      userCredentials.password &&
                      userCredentials.verifPassword
                    ) {
                      handleNextStep();
                    }
                  }}
                />
              </div>
            )}
            <div className="signup__rules">
              <div className="rules">
                <ul>
                  {passwordRules.map((passRule, index) => {
                    const key = `passRule_${index}`;
                    return (
                      <li className={passRule.isValid ? "valid" : ""} key={key}>
                        {passRule.isValid ? <IconCheck /> : <IconCross />}
                        {passRule.rule}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="signup__actions">
              <Button
                disabled={!isEmailValid || !isPasswordValid || !passwordsMatch}
                variant="primary"
                onClickAction={async (e) => {
                  handleNextStep();
                }}
              >
                {_loading ? <Spinner /> : i18next.t("auth.input.button.create")}
              </Button>

              {(brand.hasAuth.bnet || brand.hasAuth.google || brand.hasAuth.steam) && (
                <>
                  <div>
                    <p>{i18next.t("auth.authcard.or")}</p>
                  </div>

                  <FirebaseAuthMethods email={userCredentials.email} />
                </>
              )}
            </div>
          </AuthCard>
        </div>
      </div>
    </UnAuthorizedLayout>
  );
};
