import React, { memo, FC, useState, useEffect } from "react";
import { Image } from "react-bootstrap";
import ProgressBar from "@/components/ProgressBar/ProgressBar";
import { Query_Imgix } from "./../../../../api/imgix/index";
import { Query_shared } from "./../../../../api/shared/index";
import { singleChallengeProps } from "../../utils/singleChallengeProps";
import { getChallengeDate } from "../../helperFunctions/getChallengeDate";
import { getProgressBarValue } from "../../helperFunctions/getProgressBarValue";
import { ParticipateButton } from "../challengeCard/SingleChallengeCardButtons";
import BaseUserAvatar from "@/components/BaseUserAvatar";
import { Button } from "@/components";
import i18next from "i18next";
import { SingleChallengeImage } from "./SingleChallengeImage";

const SingleChallengeGeneralInfo: FC<{
  data: singleChallengeProps;
  isEdit: boolean;
  isPreview: boolean;
  isPrivateForUser: boolean;
}> = ({ data, isEdit, isPreview, isPrivateForUser }): JSX.Element => {
  const [logoError, setLogoError] = useState<boolean>(false);
  useEffect(() => {
    setLogoError(false);
  }, [data]);

  console.log(data);
  

  // Variables 
  const ChallengeType = data.challengeData.type;

  const isPrivateChallenge = ChallengeType === "private";

  return (
    <div className="info">
      <div
        className={`info__meta ${
          data.challengeData.status !== "ongoing" ? "ongoing" : ""
        }`}
      >
        <div className="info__item">
          <div className="info__image">
            <SingleChallengeImage url={data.challengeData.logoUrl} alt={data.challengeData.name} />
          </div>
          {!isPrivateForUser && data.challengeData.status === "ongoing" && (
            <div className="challenge__actions">
              <div className="challenge__participate">
                <ParticipateButton
                  data={data}
                  isPreview={
                    isPreview || data.challengeData.status !== "ongoing"
                  }
                />
              </div>
            </div>
          )}
          {!isPrivateForUser && data.challengeData.status === "finished" && (
            <div className="challenge__actions">
              <div className="challenge__participate">
                <Button 
                  variant="primary"
                  disabled={true}
                >
                  Finished
                </Button>
              </div>
            </div>
          )}
        </div>

        <div>
          <h1 className="info__heading">{data.challengeData.name}</h1>
          <div className="info__badges">
            {isPrivateChallenge && (
              <span className="info__type">{ChallengeType}</span>
            )}
            <span className="info__game">{data.challengeData.game}</span>
          </div>
          <p className="info__goal">{data.challengeData.goal}</p>

          <div className="info__participants">
            <div className="participants">
              <ul className="participants__list">
                {data.lastThreeParticipantsProfiles.map((profile, index) => {
                  return (
                    <li
                      className="participants__item"
                      key={`${profile?.userName}_${index}`}
                    >
                      <div className="participants__avatar">
                        <div className="avatar">
                          <div className="avatar__content">
                            <BaseUserAvatar
                              logoUrl={profile.profileLogoUrl}
                              userName={profile.userName}
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
              <div className="participants__names">
                <p>
                  {data.lastThreeParticipantsProfiles.map((profile, index) => {
                    return (
                      <span key={index}>
                        <span>{profile.userName}</span>
                        {index + 1 !== data.lastThreeParticipantsProfiles.length
                          ? ", "
                          : " "}
                      </span>
                    );
                  })}
                  {data.lastThreeParticipantsProfiles.length <
                  data.participantsCount - 3 ? (
                    <>
                      {i18next.t("challenge.participants.and")} <span>{data.participantsCount - 3} {i18next.t("challenge.participants.more")}</span>
                    </>
                  ) : (
                    ""
                  )}
                  {data.lastThreeParticipantsProfiles.length !== 0 && (
                    <>{i18next.t("challenge.participants.participating")}</>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isPrivateForUser && (
        <div>
          <ProgressBar
            id="challenge-time"
            label={getChallengeDate(
              data?.challengeData.startDate
                ? data?.challengeData.startDate._seconds
                : undefined,
              data?.challengeData.endDate
                ? data?.challengeData.endDate._seconds
                : undefined
            )}
            value={getProgressBarValue(
              data?.challengeData.startDate
                ? data?.challengeData.startDate._seconds
                : undefined,
              data?.challengeData.endDate
                ? data?.challengeData.endDate._seconds
                : undefined
            )}
            color=""
            onOverview={false}
            isPlaceholder={false}
          />
        </div>
      )}
    </div>
  );
};

export default memo(SingleChallengeGeneralInfo);
