import { FC } from "react";
import TrackerCard from "./components/TrackerCard";
import CtaCard from "./components/CallToActionCard";
import useDocumentTitle from "@/hooks/useDocumentTitle";
import { brand } from "../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";

import hero from "@/components/PageHero/utils/heroVariables";
import Hero from "@/components/PageHero/PageHero";
import LandingCallToAction from "../landing/components/CallToAction";
import Logitech from "../landing/assets/logitech.webp";
import TCL from "../landing/assets/tcl.webp";
import PCSpecialist from "../landing/assets/pcspecialist.webp";
import jblImage from "../../../branchAndBrandSpecific/features/partners/assets/jbl_challenge.webp";
import racingImage from "../landing/assets/elgRacing.webp";
import { siteRoutes } from "@branchAndBrandSpecific/routes/siteRoutesList";

const HomePage: FC = (): JSX.Element => {
  // Page Document Title
  useDocumentTitle("Welcome - Home");

  // Variables
  const hasTracker = brand.hasFeature.getstarted;
  const hasHero = brand.hasFeature.hero;
  
  return (
    <div className="home">
      {hasHero && (
        <Hero
          image={{
            src: hero.image.src,
            alt: hero.image.alt,
            title: hero.image.title,
            mobile: hero.image.mobile,
          }}
          heading={hero.heading}
          description={hero.description}
          btn={{
            link: hero.btn.link,
            label: hero.btn.label,
          }}
          authorName={hero.authorName}
          postedDate={""}
        />
      )}
      {brand.brandValue !== "ELGIGANTEN" && (
        <div
        className={`home__grid ${!hasTracker ? "home__grid--no-tracker" : ""}`}
        >
        {hasTracker && <TrackerCard />}
        <div
          className={`home__grid home__grid--small ${
            !hasTracker ? "home__grid--size" : ""
          }`}
          >
          <CtaCard />
        </div>
      </div>
        )}
        {brand.brandValue === "ELGIGANTEN" && (
          <>
            <LandingCallToAction 
            title={"JBL Quantum CS2 Challenge"}
            desc={
              "Put your agility and reflexes to the ultimate test in the CS2 Time trial Challenge! Navigate through our thrilling course filled with dynamic obstacles, sharp turns, and unexpected twists. Your mission: complete the course in record time. Are you ready to compete for the fastest time and claim victory? Lace up your virtual running shoes and prove your speed and skill in this exhilarating challenge!"
            }
            image={jblImage}
              link="/signup"
              label={"Sign up today"}
              float="left"
              arrow="1"
              arrowClr="green"
            />
            <LandingCallToAction 
              title={"Racing Challenge"}
              desc={"Test your racing skills and set the fastest time in our F1® 23 challenge. Try the award-winning Logitech G design. The TRUEFORCE feedback technology reacts directly to the events in the game and ensures unprecedented realism."}
              image={racingImage}
              link="/signup"
              label={"Join the challenge"}
              float="right"
              arrow="2"
              arrowClr="green"
            />
            <LandingCallToAction 
              title={"TCL 1v1 CS2 Challenge"}
              desc={"Join us for the ultimate 1v1 CS2 showdown at DreamHack, happening inside the Elgiganten store. Test your skills in intense, head-to-head battles on cutting-edge gaming setups, and try out TCL gaming monitors. Whether you're a pro or a newcomer, this is your chance to shine and dominate the competition. Don't miss the action—come and prove you're the best!"}
              image={TCL}
              link="/signup"
              label={"Challenge a friend"}
              float="left"
              // arrow="3"
              // arrowClr="green"
            />
            {/* <LandingCallToAction 
              title={"1337 Button Challenge"}
              desc={"Can you hit the stop button at exactly 1337? Test your skills participate in a raffle for a new vlogging camera! The challenge is simple: hit the stop button at exactly 1337. The closest to the target wins a prize. Are you up for the challenge?"}
              image={PCSpecialist}
              link={"/1337-challenge"}
              label={"Try it out now"}
              float="right"
            /> */}
            {/* <LandingCallToAction 
              title={"Try your Ultimate Gaming PC with PCSpecialist!"}
              desc={"Experience unrivalled Gaming PC performance in your favourite games and compete in online action with Gaming PCs from the leading custom PC provider. Our award-winning Gaming PC ranges are built with the latest technology from world-renowned brands such as Intel, AMD & Nvidia, so you can enjoy gameplay in the highest settings possible."}
              image={PCSpecialist}
              link="/signup"
              label={"Try it out now"}
              float="left"
            /> */}
          </>
        )}
    </div>
  );
};

export default HomePage;
