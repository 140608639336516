import React from "react";
import "firebase/auth";
import "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import i18next from "i18next";
import { ReactComponent as LogoutIcon } from "../assets/logout.svg";
import { SideMenuLinks } from "./SideMenuLinks";
import { Button } from "@/components";
import { siteRoutes } from "../../../routes/siteRoutesList";
import { brand } from "../../../utils/brandVariables/brandVariables";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { handleLogout } from "@/utils/sharedHelperFunctions";

const SideMenuUi: React.FunctionComponent<{ currentViewName: any }> = ({
  currentViewName,
}) => {
  const navigate = useNavigate();
  const setProfile = useCurrentUserProfile((s) => s.setUser);

  return (
    <>
      <aside className="primary-nav">
        <div className="primary-nav__brand">
          <Link to="/home">
            <img src={brand.logos.large} alt={brand.logos.alt_text} />
          </Link>
        </div>

        <SideMenuLinks currentViewName={`${currentViewName}`} />

        <div className="primary-nav__footer">
          <Button
            variant="link"
            customStyles="btn-logout"
            onClickAction={async () => {
              await handleLogout(setProfile);
              navigate(siteRoutes["landing"]);
            }}
          >
            <LogoutIcon height={18} />
            <span>{i18next.t("buttons.log_out")}</span>
          </Button>
        </div>
      </aside>
    </>
  );
};

export default SideMenuUi;
