import {
  JBL_brandVariables,
  WOG_brandVariables,
  TCL_brandVariables,
  Logitech_brandVariables,
  Elgiganten_brandVariables,
  GAMEPASS_brandVariables,
  Legion_brandVariables,
} from "./brands/index";
import BrandProps from "./brands/BrandProps";
import { JBL_winnersBrandVariables } from "./winnersData/index";
import WinnerDataProps from "./winnersData/WinnersDataProps";
import { LEGION_scheduleBrandVariables } from "./schedule/LEGION_scheduleBrandVariables";
import { FormattedListType } from "@/components/ListByDay/types/winnersTypes";
import { Elgiganten_siteRoutes } from "./siteRoutes/Elgiganten_siteRoutes";
import { bestgamer_brandVariables } from "./brands/BESTGAMER_brandvariables";

const brands = {
  "elgiganten.iplay.dk": {
    brandData: Elgiganten_brandVariables,
    // winnersPageData: undefined,
    // schedulePageData: undefined,
  }, // Elgiganten

  "elgiganten-event.web.app": {
    brandData: Elgiganten_brandVariables,
    // winnersPageData: undefined,
    // schedulePageData: undefined,
  }, // Elgiganten

  "joinlegion.gg": {
    brandData: Legion_brandVariables,
    // winnersPageData: undefined,
    // schedulePageData: LEGION_scheduleBrandVariables,
  }, // Elgiganten

  "tcl.iplay.dk": {
    brandData: TCL_brandVariables,
    // winnersPageData: undefined,
    // schedulePageData: undefined,
  }, // TCL

  "localhost:5173": {
    brandData: Elgiganten_brandVariables,
    // winnersPageData: JBL_winnersBrandVariables,
    // schedulePageData: LEGION_scheduleBrandVariables,
  }, // Set for local development

  "192.168.1.153:5173": {
    brandData: Elgiganten_brandVariables,
  },

  "logitech-iplay.web.app": {
    brandData: Logitech_brandVariables,
  },

  "localhost:5174": {
    brandData: Elgiganten_brandVariables,
    // winnersPageData: JBL_winnersBrandVariables,
    // schedulePageData: LEGION_scheduleBrandVariables,
  }, // Set for local development

  default: {
    brandData: WOG_brandVariables,
    // winnersPageData: JBL_winnersBrandVariables,
    // schedulePageData: LEGION_scheduleBrandVariables,
  }, // Fallback to WOG

  // Brands, can be called individualy with getBrandVariables(client)
  WOG: WOG_brandVariables,
  TCL: TCL_brandVariables,
  LEGION: Legion_brandVariables,
  JBL: JBL_brandVariables,
  ELGIGANTEN: Elgiganten_brandVariables,
  GAMEPASS: GAMEPASS_brandVariables,
  LOGITECH: Logitech_brandVariables,
};

export const getBrandVariables = (client?: string) => {
  if (client) {
    const brandVariables = brands[client];
    console.log(brandVariables);

    return brandVariables as {
      brandData: BrandProps;
      winnersPageData: WinnerDataProps;
      schedulePageData: {
        header: string;
        subHeader: string;
        sideMenuTitle: string;
        scheduleData: FormattedListType[];
      };
    };
  } else {
    const hostname = window.location.host;
    const brandVariables = brands[hostname] || brands["default"];
    return brandVariables as {
      brandData: BrandProps;
      winnersPageData: WinnerDataProps;
      schedulePageData: {
        header: string;
        subHeader: string;
        sideMenuTitle: string;
        scheduleData: FormattedListType[];
      };
    };
  }
};

export const brand = getBrandVariables().brandData;
export const winnerData = getBrandVariables().winnersPageData;
export const scheduleData = getBrandVariables().schedulePageData;
