import React from "react";
import { ReactComponent as ElgigantenBadge } from "../../assets/elgiganten_stamp.svg";

type Props = {
    scoreInProcentage: string | number
};

const CompletionBadge: React.FC<Props> = ({
    scoreInProcentage
}) => {

    return (
        <div className="completion-badge">
            <ElgigantenBadge className="completion-badge__image" />
            <div 
                className="completion-badge__overlay"
                style={{ height: `${scoreInProcentage}%` }}>
                <ElgigantenBadge />
            </div>
            {scoreInProcentage !== 100 && (
                <span className="completion-badge__incomplete">
                    <p>{scoreInProcentage}&#37;</p>
                </span>
            )}
        </div>
    );
};

export default CompletionBadge;