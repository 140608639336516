import { useHighlight } from "@/features/challenge/hooks/useHighlight";
import i18next from "i18next";
import React, { FC, useEffect, useState, useRef } from "react";
import { ReactComponent as IconBottomWave } from "../../assets/icons/bottomWave.svg";
import { ReactComponent as IconTopWave } from "../../assets/icons/topWave.svg";
import { ReactComponent as IconPlay } from "../../assets/icons/highlightPlay.svg";

export const Highlights: FC<{
    game: string,
}> = ({
    game,
}): JSX.Element => { 
    const currentGame = game.toLowerCase();
    const [ highlight ] = useHighlight(currentGame);
    const [isPlaying, setIsPlaying] = useState(false);
    const [hasGradient, setHasGradient] = useState<boolean>(true);
    const videoRef = useRef(null);

    const handlePlay = () => {
        if (videoRef.current) {
            videoRef.current.play();
            setIsPlaying(true);
            setHasGradient(false)
        }
    }

    useEffect(() => {
        if (highlight) {
            videoRef.current.currentTime = 0;
            setHasGradient(true);
        } 
    }, [highlight])

    const resetVideo = () => {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
        setIsPlaying(false);
        setHasGradient(true);
    }

    const handlePause = () => {
        if (videoRef.current) {
            videoRef.current.pause();
            setIsPlaying(false);
            setHasGradient(true);
        }
    }

    console.log(currentGame);
    console.log(highlight);

    const nvidiaHighlightSupportedGames = {
        PUBG: i18next.t("challenge.highlights.pubg"),
        Fortnite: i18next.t("challenge.highlights.fortnite"),
    }

    if (nvidiaHighlightSupportedGames[game] && highlight) {
        return (
            <div className="highlights">
                <h2>
                    {i18next.t("challenge.highlights.heading")}
                </h2>
                <p>
                    {nvidiaHighlightSupportedGames[game]}
                </p>

                <div className="highlights__content">
                    <div className={hasGradient ? "highlights__video highlights__video--overlay" : "highlights__video"}>
                        <div className="highlights__top">
                            <IconTopWave />
                        </div>
                        <video 
                            ref={videoRef}
                            width="100%"
                            disablePictureInPicture
                            muted
                            onEnded={resetVideo}
                            >
                            <source src={highlight.url} type="video/mp4" />
                        </video> 
                        {isPlaying && (
                            <div className="highlights__controls" onClick={handlePause} />
                        )}
                        {!isPlaying && (
                            <div className="highlights__controls">
                                <div className="highlights__play" onClick={handlePlay}>
                                    <IconPlay />
                                </div>
                            </div>
                        )}
                        <div className="highlights__bottom">
                            <IconBottomWave />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return;
};